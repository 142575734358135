import { NumericFormat } from "react-number-format";
import { FallBackImage } from "../default/fallback-image";

export default function HomeLastOffers({ ads }) {
    return (
        <>
            <section className="latest-collection pb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-12">
                            <div className="at-section-title text-center text-lg-start">
                                <span className="at-subtitle position-relative lead text-primary">Últimas Ofertas</span>
                                <h2 className="h3 mt-2 mb-0">Confira as nossas últimas ofertas!</h2>
                            </div>
                        </div>
                    </div>
                    <div className="filter-items-wrapper mt-5">
                        <div className="row g-4 justify-content-center filter-grid">
                            {
                                ads.items.map((item, index) => <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xxs-12" key={index}>
                                    <div className="filter-card-item position-relative overflow-hidden rounded bg-white">
                                        <div
                                            className="feature-thumb position-relative overflow-hidden"
                                            style={{
                                                position: 'relative',
                                                width: '100%',
                                                maxWidth: '430px',
                                                aspectRatio: '70/45'
                                            }}>
                                            <a href={`anuncio/${item.category}/${`${item.brand.split(' ').join('-').toLowerCase()}-${item.model.split(' ').join('-').toLowerCase()}`}/${item.yearModel}/${item.id}`}>
                                                <FallBackImage
                                                    className='img-card-fluid'
                                                    priority={true}
                                                    alt={`${item.brand} ${item.model}`}
                                                    src={item.photos.length > 0 ? item.photos[0] : "/img/no-image.jpeg"} />
                                            </a>
                                        </div>
                                        <div className="filter-card-content">
                                            <div className="price-btn text-end position-relative">
                                                <span className="small-btn-meta">
                                                    {
                                                        item.price
                                                            ? <NumericFormat
                                                                value={item.price ?? 0}
                                                                displayType={'text'}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                prefix='R$ ' />
                                                            : 'consulte-nos'
                                                    }
                                                </span>
                                            </div>
                                            <a
                                                href={`anuncio/${item.category}/${`${item.brand.split(' ').join('-').toLowerCase()}-${item.model.split(' ').join('-').toLowerCase()}`}/${item.yearModel}/${item.id}`}
                                                className="mt-2 d-block">
                                                <span className="h5">{`${item.brand} ${item.model}`}</span>
                                            </a>
                                            <span
                                                className="meta-content"
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    display: 'inline-block',
                                                    width: '100%'
                                                }}>{item.version}</span>
                                            <hr className="spacer mt-3 mb-3" />
                                            <div className="card-feature-box d-flex align-items-center mb-4">
                                                <div
                                                    className="icon-box d-flex align-items-center"
                                                    title={item.transmission}
                                                    style={{
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        display: 'inline-block',
                                                        width: '100%'
                                                    }}>
                                                    <span className="me-2"><i className="flaticon-steering-wheel"></i></span>
                                                    {item.transmission}
                                                </div>
                                                <div
                                                    className="icon-box d-flex align-items-center"
                                                    title={item.fuel}
                                                    style={{
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        display: 'inline-block',
                                                        width: '100%'
                                                    }}>
                                                    <span className="me-2"><i className="flaticon-petrol"></i></span>
                                                    {item.fuel}
                                                </div>
                                            </div>
                                            <a href={`anuncio/${item.category}/${`${item.brand.split(' ').join('-').toLowerCase()}-${item.model.split(' ').join('-').toLowerCase()}`}/${item.yearModel}/${item.id}`} className="btn outline-btn btn-sm d-block">ver detalhes</a>
                                        </div>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <a href="/estoque" className="at-explore-btn color-secondary">
                            <span className="me-2">
                                <svg width="39" height="26" viewBox="0 0 39 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg">
                                    <path d="M32.7143 7.85706L37.5142 12.9999M37.5142 12.9999L32.7143 18.1428M37.5142 12.9999H18.1428" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round" />
                                    <circle cx="13" cy="13" r="12" strokeWidth="1.71429" />
                                </svg>
                            </span>ver mais ofertas
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}